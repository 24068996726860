<template>
  <div class="elv-match-detail-dialog-content-table-detail">
    <h6>
      {{ t('common.accountingLedger') }}:<span>{{ props.tableList?.length || 0 }}</span>
    </h6>
    <el-table
      ref="tableListRef"
      :data="props.tableList"
      border
      stripe
      max-height="500"
      class="elv-reconciliation-match-detail-table"
      header-cell-class-name="elv-reconciliation-match-detail-table-header__cell"
      header-row-class-name="elv-reconciliation-match-detail-table-header"
      row-class-name="elv-reconciliation-match-detail-table-row"
      cell-class-name="elv-reconciliation-match-detail-table-row__cell"
    >
      <el-table-column width="32" label="">
        <template #default="{ row }">
          <IconWithTitleCell
            :title="ledgerShowInfo(row).balanceType || ''"
            icon=""
            titleFontFamily="Plus Jakarta Sans"
            :titleWeight="400"
            titleSize="13px"
          />
        </template>
      </el-table-column>
      <el-table-column width="100" :label="t('common.account')">
        <template #default="{ row }">
          <VerticalTextCell
            :upText="row.chartOfAccount?.name || ''"
            upFontFamily="Plus Jakarta Sans"
            upFontSize="13px"
            :upWeight="400"
            :downText="row.journal?.journalType?.name || ''"
            downFontFamily="Barlow"
            :downWeight="400"
            downFontSize="11px"
            align="left"
          />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.dateTime')">
        <template #default="{ row }">
          <div class="elv-match-detail-dialog-content-item-datetime">
            <span> {{ dayjs.utc(row?.datetime).format('YYYY/MM/DD') }}</span>
            <span v-if="row.journal?.journalNo"
              >{{ row.journal?.journalNo
              }}<img
                src="@/assets/img/copy.png"
                alt=""
                class="elv-financials-cell-date-time-main-bottom__copy"
                @click.stop="onClickedCopy(row.journal?.journalNo)"
            /></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="75" :label="t('common.currency')">
        <template #default="{ row }">
          <IconWithTitleCell
            :title="row.currency?.showSymbol || ''"
            :icon="row.currency?.logo || ''"
            titleFontFamily="Barlow"
            :titleWeight="400"
            titleSize="13px"
          />
        </template>
      </el-table-column>
      <el-table-column width="90" header-align="right" :label="t('common.amount')">
        <template #default="{ row }">
          <IconWithTitleCell
            class="elv-reconciliation-match-detail-table-row__cell-amount"
            :title="ledgerShowInfo(row).amount || ''"
            icon=""
            titleFontFamily="Barlow"
            :titleWeight="500"
            titleSize="13px"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'

const props = defineProps({
  tableList: {
    type: Array,
    required: true
  }
})

const { t } = useI18n()
const { toClipboard } = useClipboard()

const ledgerShowInfo = computed(() => (data: any) => {
  const ledgerInfo = { balanceType: '', amount: '' }
  const creditFC = data.creditFC ? +data.creditFC : 0
  const credit = data.credit ? +data.credit : 0
  ledgerInfo.balanceType = creditFC !== 0 ? 'Cr' : 'Dr'
  ledgerInfo.amount = formatNumberToSignificantDigits(credit !== 0 ? data.credit : data.debit, 2, '', false)
  return ledgerInfo
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}
</script>

<style lang="scss" scoped>
.elv-match-detail-dialog-content-table-detail {
  width: 409px;

  h6 {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    line-height: 15px;

    span {
      font-family: 'Barlow';
      font-weight: 500;
      color: #636b75;
      margin-left: 4px;
    }
  }
}
</style>
