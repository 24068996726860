<template>
  <div>
    <el-dialog
      v-model="showMatchDetailDialog"
      width="30%"
      align-center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="elv-match-detail-dialog"
      @close="onCloseMatchDetailDialog"
    >
      <template #header="{ titleId }">
        <h4 :id="titleId" class="elv-match-detail-dialog-header__title">
          {{ t('report.matchDetail') }}
        </h4>
      </template>
      <el-scrollbar
        v-loading="loading"
        max-height="calc(100vh - 280px)"
        class="elv-match-detail-dialog-container"
        view-class="elv-match-detail-dialog-container__view"
      >
        <div class="elv-match-detail-dialog-info">
          <div class="elv-match-detail-dialog-content-header">
            <div class="elv-match-detail-dialog-content-header__info">
              <h5>{{ reconciliationMatchDetail?.matchNo }}</h5>
              <p>
                <span>{{
                  dayjs(reconciliationMatchDetail?.createdAt)
                    .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                    .format('YYYY/MM/DD HH:mm')
                }}</span>
                <span
                  v-if="reconciliationMatchDetail?.user?.name || reconciliationMatchDetail?.reconciliationRule?.name"
                  class="line"
                ></span>
                <SvgIcon name="select--window" width="14" height="14" />
                {{
                  reconciliationMatchDetail?.user?.name
                    ? `${t('reconciliation.manualMatch')}: ${reconciliationMatchDetail?.user?.name}`
                    : `${t('reconciliation.matchedByRule', { ruleName: reconciliationMatchDetail?.reconciliationRule?.name })}`
                }}
              </p>
            </div>
            <div class="elv-match-detail-dialog-content-header__operating">
              <div class="elv-match-detail-difference">
                {{ t('common.difference') }}:<span
                  :label="reconciliationMatchDetail.difference"
                  :class="{
                    'elv-match-detail-difference-positive':
                      !reconciliationMatchDetail?.difference ||
                      new Big(reconciliationMatchDetail?.difference ?? '0').eq(0)
                  }"
                  >{{
                    formatNumberToSignificantDigits(reconciliationMatchDetail.difference ?? '0', 2, '', false) || 0
                  }}</span
                >
              </div>
              <elv-button
                class="elv-match-detail-button-unmatched"
                :loading="unMatchedLoading"
                @click="onReconciliationUnMatch"
              >
                <!-- @click="onOpenMatchedConfirmDialog" -->
                <SvgIcon name="unlink" width="14" height="14" />{{ t('report.unmatch') }}
              </elv-button>
            </div>
          </div>
          <div class="elv-match-detail-dialog-content">
            <component
              :is="currentComponent('LEFT')"
              v-if="currentComponent('LEFT')"
              :tableList="reconciliationMatchDataSetADetailList"
            />
            <component
              :is="currentComponent('RIGHT')"
              v-if="currentComponent('RIGHT')"
              :tableList="reconciliationMatchDataSetBDetailList"
            />
          </div>
        </div>
        <ExplanationReasonForm
          v-if="isShowDifferenceReason"
          ref="explanationReasonFormRef"
          v-model="explanationReasonForm"
          class="elv-match-detail-dialog-explanation-reason"
          :reasonLabel="$t('reconciliation.differenceReason')"
          :model="currentExplanationReasonModel"
          :disableSave="isRuleGenerated"
          :difference="reconciliationMatchDetail?.difference || '0'"
          :adjustmentJournalTitle="$t('reconciliation.reconciliationAdjustmentJournal')"
          dataType="RECONCILIATION"
          :currentData="reconciliationMatchDetail"
        />
      </el-scrollbar>

      <template v-if="isShowDifferenceReason && !isRuleGenerated" #footer>
        <el-button
          class="elv-match-detail-dialog-close-btn"
          type="primary"
          :loading="saveLoading"
          @click="onSaveMatchDetailChange"
        >
          {{ t('button.saveChanges') }}
        </el-button>
      </template>
    </el-dialog>
    <ReconciliationTaskStatusChangeDialog
      v-if="showUnMatchConfirmDialog"
      v-model:show="showUnMatchConfirmDialog"
      v-model:agree="agreeRemovedData"
      v-model:loading="unMatchedLoading"
      :title="t('report.unmatch')"
      :confirmText="t('button.confirm')"
      :cancelText="t('button.cancel')"
      @onConfirmChangeStatus="onUnmatchedReconciliation"
      @onCloseDialog="showUnMatchConfirmDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import { find, cloneDeep, isEmpty } from 'lodash-es'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import MatchedLedgerTable from './SideTable/MatchedLedgerTable.vue'
import { formatNumberToSignificantDigits } from '@/lib/utils'
import MatchedTransferTable from './SideTable/MatchedTransferTable.vue'
import { ReconciliationMatchedDetailType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import ExplanationReasonForm from '../../Reconciliation/components/ExplanationReasonForm.vue'
import ReconciliationTaskStatusChangeDialog from '../../Reconciliation/components/ReconciliationTaskStatusChangeDialog.vue'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  tableType: {
    type: String,
    default: 'MATCHES' // TRANSACTION | PROCESSED
  },
  reconciliationMatchId: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['onResetList'])

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()

const loading = ref(false)
const agreeRemovedData = ref(false)
const unMatchedLoading = ref(false)
const saveLoading = ref(false)
const showUnMatchConfirmDialog = ref(false)
const reconciliationMatchDetail = ref<ReconciliationMatchedDetailType>({} as ReconciliationMatchedDetailType)
const explanationReasonFormRef = useTemplateRef('explanationReasonFormRef')

const explanationReasonForm = ref({
  reconciliationReasonId: '',
  name: '',
  description: '',
  chartOfAccountId: '',
  auxiliaryValueList: [],
  memo: '',
  journalAction: 'KEEP'
})

const matchedTypeComponentMap = {
  LEDGER: MatchedLedgerTable,
  TRANSACTION: MatchedTransferTable
}

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId || '')
})

const currentComponent = computed(() => (positionType: 'LEFT' | 'RIGHT') => {
  if (positionType === 'LEFT') {
    const sideAType = reconciliationMatchDetail.value?.reconciliationTask
      ?.dataSetTypeA as keyof typeof matchedTypeComponentMap
    return matchedTypeComponentMap[sideAType]
  }
  if (positionType === 'RIGHT') {
    const sideBType = reconciliationMatchDetail?.value?.reconciliationTask
      ?.dataSetTypeB as keyof typeof matchedTypeComponentMap
    return matchedTypeComponentMap[sideBType]
  }
  return null
})

const isRuleGenerated = computed(() => {
  return !!reconciliationMatchDetail.value?.reconciliationRule?.reconciliationRuleId
})

const reconciliationMatchDataSetADetailList = computed(() => {
  if (!reconciliationMatchDetail.value?.dataSetAList?.length) {
    return []
  }
  return reconciliationMatchDetail.value?.dataSetAList
})

const reconciliationMatchDataSetBDetailList = computed(() => {
  if (!reconciliationMatchDetail.value?.dataSetBList?.length) {
    return []
  }
  return reconciliationMatchDetail.value?.dataSetBList
})

const isShowDifferenceReason = computed(() => {
  return (
    reconciliationMatchDetail.value?.difference && !new Big(reconciliationMatchDetail.value?.difference ?? '0').eq(0)
  )
})

const contentPaddingBottom = computed(() => {
  if (isRuleGenerated.value && isShowDifferenceReason.value) {
    return '32px'
  }
  return isShowDifferenceReason.value ? '10px' : '32px'
})

const currentExplanationReasonModel = computed(() => {
  return isEmpty(
    !new Big(reconciliationMatchDetail.value?.difference ?? '0').eq(0) ||
      !isEmpty(reconciliationMatchDetail.value.reconciliationReason)
  )
    ? 'edit'
    : 'add'
})

const showMatchDetailDialog = ref(false)

/**
 * @description: 取消匹配对账
 */
const onUnmatchedReconciliation = async () => {
  try {
    unMatchedLoading.value = true
    await ReconciliationApi.manualReconciliationUnmatched(
      entityId.value,
      reconciliationMatchDetail.value?.reconciliationTask?.reconciliationTaskId,
      {
        reconciliationMatchIds: [props.reconciliationMatchId],
        isRemoveReconciliationAdjustment: isRuleGenerated?.value ? true : agreeRemovedData.value
      }
    )
    emit('onResetList')
    ElMessage.success(t('message.success'))
    showUnMatchConfirmDialog.value = false
    showMatchDetailDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    unMatchedLoading.value = false
  }
}

/**
 * @description: 打开匹配确认对话框
 */
const onOpenMatchedConfirmDialog = () => {
  showUnMatchConfirmDialog.value = true
}

/**
 * @description: 取消匹配对账
 */
const onReconciliationUnMatch = () => {
  if (
    reconciliationMatchDetail.value?.reconciliationExplain?.reconciliationExplainId &&
    reconciliationMatchDetail.value?.journalActivity?.journalActivityId &&
    !isRuleGenerated.value
  ) {
    onOpenMatchedConfirmDialog()
    return
  }
  onUnmatchedReconciliation()
}

/**
 * @description: 切换匹配详情对话框
 */
const onCheckMatchDetailDialog = () => {
  showMatchDetailDialog.value = !showMatchDetailDialog.value
}

/**
 * 保存已匹配列表详情更改
 */
const onSaveMatchDetailChange = async () => {
  explanationReasonFormRef.value?.explanationReasonFormRef?.validate(async (valid: boolean) => {
    if (valid) {
      try {
        saveLoading.value = true
        const data: any = cloneDeep(explanationReasonForm.value)
        const reason = find(reconciliationStore.reconciliationTaskReasonList, {
          reconciliationReasonId: data.reconciliationReasonId
        })
        if (isEmpty(reason)) {
          data.name = data.reconciliationReasonId
          delete data.reconciliationReasonId
        } else {
          delete data.name
        }
        await ReconciliationApi.updateReconciliationMatch(
          entityId.value,
          reconciliationTaskId.value,
          props.reconciliationMatchId,
          data
        )
        ElMessage.success(t('message.saveSuccess'))
        if (props.tableType === 'PROCESSED') {
          emit('onResetList')
        }
        onCheckMatchDetailDialog()
      } catch (error: any) {
        ElMessage.error(error.message)
      } finally {
        saveLoading.value = false
      }
    }
  })
}

/**
 * @description: 关闭匹配详情对话框
 */
const onCloseMatchDetailDialog = () => {
  reconciliationMatchDetail.value = {} as ReconciliationMatchedDetailType
  explanationReasonForm.value = {
    reconciliationReasonId: '',
    name: '',
    description: '',
    chartOfAccountId: '',
    auxiliaryValueList: [],
    memo: '',
    journalAction: 'KEEP'
  }
  agreeRemovedData.value = false
}

defineExpose({ onCheckMatchDetailDialog })

watch([() => showMatchDetailDialog.value, () => props.reconciliationMatchId], async () => {
  if (showMatchDetailDialog.value && props.reconciliationMatchId !== '') {
    try {
      loading.value = true
      const { data } = await ReconciliationApi.getReconciliationMatchDetail(
        entityId.value,
        reconciliationTaskId.value || props.currentData.reconciliationTaskId,
        props.reconciliationMatchId
      )
      reconciliationMatchDetail.value = data
      reconciliationMatchDetail.value = {
        ...reconciliationMatchDetail.value,
        ...reconciliationMatchDetail.value?.reason
      }
      explanationReasonForm.value.name = reconciliationMatchDetail.value?.reason?.name || ''
      explanationReasonForm.value.description =
        reconciliationMatchDetail.value?.reconciliationExplain?.description || ''
      explanationReasonForm.value.reconciliationReasonId =
        reconciliationMatchDetail.value?.reason?.reconciliationReasonId || ''
    } catch (error: any) {
      ElMessage.error(error.message)
    } finally {
      loading.value = false
    }
  }
})
</script>

<style lang="scss">
.elv-match-detail-dialog {
  width: 900px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-match-detail-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-scrollbar.elv-match-detail-dialog-container {
      width: 100%;

      .elv-match-detail-dialog-container__view {
        width: 100%;
        padding: 18px 24px v-bind('contentPaddingBottom');
      }
    }

    .elv-match-detail-dialog-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f9fafb;
      border: 1px solid #dde1e6;
      border-radius: 5px;
      padding: 14px 12px;
    }

    .elv-match-detail-dialog-explanation-reason {
      margin-top: 10px;
    }

    .elv-match-detail-dialog-content-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid #edf0f3;
      margin-bottom: 10px;
      width: 100%;

      .elv-match-detail-dialog-content-header__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        h5 {
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #0e0f11;
        }

        p {
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          line-height: 14px;
          font-style: normal;
          font-weight: 400;
          margin-top: 6px;
          display: flex;
          align-items: center;

          span {
            color: #aaafb6;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
          }

          svg {
            margin-right: 8px;
          }

          .line {
            display: block;
            width: 1px;
            height: 10px;
            background-color: #d0d4d9;
            margin: 0px 8px;
          }
        }
      }

      .elv-match-detail-dialog-content-header__operating {
        display: flex;
        align-items: center;
      }

      .elv-match-detail-button-unmatched {
        display: flex;
        height: 30px;
        padding: 0px 12px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        background: #e5edff;
        cursor: pointer;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        svg {
          margin-right: 4px;
          fill: #1e2024;
        }

        .el-icon.is-loading {
          svg {
            margin-right: 0;
          }
        }
      }

      .elv-match-detail-difference {
        margin-right: 24px;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-weight: 400;
        color: #838d95;
        display: flex;
        align-items: center;

        span {
          font-family: 'Barlow';
          color: #ff4947;
          font-weight: 600;
          margin-left: 8px;

          &.elv-match-detail-difference-positive {
            color: #009347;
          }
        }
      }
    }

    .elv-match-detail-dialog-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 12px;
    }
  }

  .el-dialog__footer {
    padding: 0px;
    padding-bottom: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .elv-match-detail-dialog-close-btn {
      width: 156px;
      height: 44px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'PingFang SC';
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 16px;
    }
  }
}

.elv-reconciliation-match-detail-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;
  }

  .el-table__body-wrapper {
    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-reconciliation-match-detail-table-header {
    background: #ffffff;

    .elv-reconciliation-match-detail-table-header__cell {
      background: #ffffff;
      height: 23px;
      box-sizing: border-box;
      border-right-color: #edf0f3;
      border-bottom-color: #edf0f3;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #b2bacc;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-match-detail-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      display: flex;
      align-items: center;

      .elv-base-cell-icon-title {
        padding: 0px;
      }
    }

    &.elv-reconciliation-match-detail-table-row__cell .cell {
      padding: 0px;
      position: relative;

      .elv-match-detail-dialog-content-item-transfer-type {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .elv-match-detail-dialog-content-item-datetime {
        display: flex;
        flex-direction: column;
        padding: 0px 10px;

        span {
          display: flex;
          align-items: center;
          font-family: 'Barlow';
          font-size: 13px;
          font-weight: 400;
          line-height: 15px;
          color: #0e0f11;

          &:last-child {
            margin-top: 2px;
            font-size: 11px;
            line-height: 13px;
            color: #6b7177;
          }

          img {
            width: 10px;
            height: 10px;
            margin-left: 4px;
            margin-top: 2px;
          }
        }
      }

      .elv-base-cell-icon-title {
        padding: 0px 10px;

        .elv-base-cell-icon-title-img {
          margin-right: 4px;
        }
      }

      .elv-reconciliation-match-detail-table-row__cell-amount {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      .elv-base-cell-vertical-text {
        width: 100%;
        padding: 0px 10px;

        div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    &.is-right .cell {
      text-align: right;
      justify-content: flex-end;
    }

    &.is-center .cell {
      text-align: center;
      justify-content: center;
    }
  }
}
</style>
